

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // initialization of go to


                // initialization of video player
                $('.js-inline-video-player').each(function () {
                  var videoPlayer = new HSVideoPlayer($(this)).init();
                });


        $('.cbp').each(function () {
             var cbp = $.HSCore.components.HSCubeportfolio.init($(this), {
               layoutMode: 'grid',
               filters: '#filterControls',
             });
           });

        $('.cbpMobile').each(function () {
             var cbp = $.HSCore.components.HSCubeportfolio.init($(this), {
               layoutMode: 'grid',
               filters: '#filterControlsMobile',
             });
           });

        $('.js-slick-carousel').each(function() {
       var slickCarousel = $.HSCore.components.HSSlickCarousel.init($(this));
      });

      // initialization of unfold
      var unfold = new HSUnfold('.js-hs-unfold-invoker').init();

      // initialization of unfold
      $('.js-hs-search-unfold-invoker').each(function() {
        var searchUnfold = new HSUnfold($(this), {
          afterOpen: function() {
            $('#searchSlideDownControl').focus();
          }
        }).init();
      });





      // initialization of show animations
   $('.js-animation-link').each(function () {
     var showAnimation = new HSShowAnimation($(this)).init();
   });



          }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on('load', function () {

    var megaMenu = new HSMegaMenu($('.js-mega-menu')).init();



  });



  $(document).on('ready', function () {





    var hash = window.location.hash;
    hash && $('#docs a[href="' + hash + '"]').tab('show');

    $('.nav-item a').click(function (e) {
      $(this).tab('show');
      var scrollmem = $('#docs').scrollTop();
      window.location.hash = this.hash;
      $('#docs').scrollTop(scrollmem);
    });


    // initialization of slick carousel
    var megaMenu = new HSMegaMenu($('.js-mega-menu')).init();
    var header = new HSHeader($('#header')).init();

    $('.responsive2').slick({
  dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


   });
  // JavaScript to be fired on all pages, after page specific JS is fired


})(jQuery); // Fully reference jQuery after this point.
